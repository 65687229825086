<template>
	<div data-route>
		<article
			data-element="article"
			v-if="getArticle"
		>
			<h1 data-element="page-heading">
				{{ getTitle }}
			</h1>
			<div
				data-element="content"
				v-html="getContent"
			/>
		</article>
		<aside
			data-element="article-sidebar"
		>
			<sidebar-list
				v-if="getShowCategoriesSidebar"
				heading="Other categories"
				:listItems="getCategoriesSidebarListItems"
				listItemIcon="info"
			/>
			<sidebar-list
				v-if="getShowRelatedSidebar"
				:heading="getRelatedSidebarHeading"
				:listItems="getRelatedSidebarListItems"
				listItemIcon="book"
				moreLinkText="More articles"
				:moreLinkRoute="getRelatedSidebarMoreLinkRoute"
			/>
		</aside>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>

	import routeParams  from '@/mixins/routeParams';
	import api          from '@/services/api';
	import SidebarList  from '@/components/knowledgebase/view/SidebarList';

	export default {
		metaInfo: {
			title: 'About'
		},
		components: {
			SidebarList
		},
		mixins: [routeParams],
		data: () => ({
			article: {},
			category: {}
		}),
		computed: {
			getArticle () {
				if (!this.article?.id) {
					return false;
				}
				return this.article;
			},
			getTitle () {
				if (!this.article?.title?.length) {
					return false;
				}
				return this.article.title;
			},
			getContent () {
				if (!this.article?.content?.length) {
					return false;
				}
				return this.article.content;
			},
			getArticleCategories () {
				if (!this.article?.categories?.length) {
					return false;
				}
				return this.article.categories;
			},
			getCategorySlug () {
				return this.$route.params.categorySlug;
			},
			getShowCategoriesSidebar () {
				if (!this.getCategoriesSidebarListItems.length ||
						!this.getCategoriesSidebarHeading) {
					return false;
				}
				return true;
			},
			getCategoriesSidebarHeading () {
				return 'Other categories';
			},
			getCategoriesSidebarListItems () {
				if (!this.article?.allCategories) {
					return [];
				}
				return this.article.allCategories.map((category) => {
					return {
						text: category.title,
						route: `/about/${category.slug}`
					};
				});
			},
			getShowRelatedSidebar () {
				if (!this.getRelatedSidebarListItems.length ||
						!this.getRelatedSidebarHeading) {
					return false;
				}
				return true;
			},
			getRelatedSidebarHeading () {
				return 'Other articles in this category';
			},
			getRelatedSidebarListItems () {
				if (!this.article?.allCategories) {
					return [];
				}
				return this.article.related.map((article) => {
					return {
						text: article.title,
						route: `/about/${this.getCategorySlug}/${article.slug}`
					};
				});
			},
			getRelatedSidebarMoreLinkRoute () {
				return `/about/${this.getCategorySlug}`;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/about',
						text: 'About'
					},
					{
						path: `/about/${this.getCategorySlug}`,
						text: this.article.category[0]
					},
					{
						path: `/about/${this.getCategorySlug}/${this.getArticleSlug}`,
						text: this.getTitle
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setArticle();
		},
		methods: {
			async setArticle () {
				const article = await api.knowledgebase.getArticleBySlugAndCategorySlug({
					articleSlug:   this.$route.params.articleSlug,
					categorySlug:  this.$route.params.categorySlug,
					include: 'categories'
				});
				if (!article) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.article = article;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-route] {
		[data-element='article'] {
			@include cols(12);
			@include mq('sm') {
				@include cols(7);
				@include gut();
			}
			margin-bottom:rem(64);
			border-radius:8px;
			&:nth-of-type(3n) {
				margin-right:0;
			}
			[data-element='page-heading'] {
				margin-top:0;
				margin-bottom:rem(28);
				font-family:'Montserrat', sans-serif;
				font-size:rem(40);
				font-weight:700;
				color:$c-brand-blue;
			}
			::v-deep [data-element='content'] {
				font-size:rem(20);
				@include rich-text-content;
				@include rich-text-suits;
			}
		}
		[data-element='article-sidebar'] {
			@include cols(12);
			@include mq('sm') {
				@include cols(4);
				@include push(1);
			}
			[data-component='sidebar-list'] {
				margin:0 0 rem(28);
			}
		}
	}

</style>
